html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans KR';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: var(--app-height);
  overflow-y: scroll;
  overflow-x: visible;
  -ms-overflow-style: none;
}
code {
  font-family: 'Spoqa Han Sans Neo', 'Spoqa Han Sans KR';
}

::-webkit-scrollbar {
  position: absolute;
  height: 17px;
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e2e2e2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 7px;
  border: 5px solid #e2e2e2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
  height: 7px;
}

.tabContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 100;
    left: 0;
    right: 0;
    bottom: 0 !important;
    background: var(--whitef9);
    backdrop-filter: blur(27.1828px);
    box-shadow: 0px 0.5px 0px 0px #00000033 inset;
}

.absolute {
    position: absolute;
}

.fixed {
     position: fixed;
 }

.tabInner {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: var(--tab-height);
    padding-top: 24px;
}

.tabActive {
    padding-top: 5px;
    text-align: center;
    color: var(--primary)
}

.tabText {
    padding-top: 5px;
    text-align: center;
    color: #00000073;
}

.tabCount {
    padding: 0px 6px;
    position: absolute;
    right: 25px;
    top: -5px;
    background: var(--badge);
    border-radius: 10px;
    z-index: 1000;
    color: white;
    height: 20px;
    min-width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tabBadge {
    position: absolute;
    right: 32px;
    top: 3px;
    width: 6px;
    height: 6px;
    background: var(--badge);
    box-shadow: 0px 0px 0px #FFFFFF;
    border-radius: 32px;
}

.tabLink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 106px;
}

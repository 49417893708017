.intro-container {
    padding-top: 26vh;
}

.intro-text {
    color: white;
    padding-top: 32px;
    white-space: pre-line;
}

.button {
    margin-top: 60px;
}


.expired-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.expired-info-dark {
    text-align: center;
    white-space: pre-line;
    color: var(--black85)
}

.expired-info {
    padding-left: 5px;
    color: var(--black45)
}

.expired-progress {
    height: 12px;
    width: 80px;
    display: flex;
    align-items: center;
    padding: 0px;
    margin-bottom: 56px;
}

.expired-btn {
    margin-top: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 245px;
}


.container {
    background: none;
    padding: 0px;
    margin: 0px;
    height: 68px
}

.radioGrp {
    margin: 16px 0px
}

.list {
    min-height: 300px;
}

.subtitle {
    margin: 5px;
}

.name {
    margin-left: 8px;
    margin-right: auto;
    max-width: 140px;
    padding: 5px 0px;
}

.phone {
    /*margin-top: 5px;*/
    /*margin-bottom: 5px;*/
}

.type {
    border-radius: 4px;
    text-align: center;
    line-height: 20px;
    height: 20px;
    min-width: 80px;
}

.dot {
    width: 6px;
    height: 6px;
    background: var(--primary);
    border-radius: 33px;
    margin-right: 10px;
}

.red {
    background: #FF4D4F;
}

.green {
    background: #52C41A;
}

.box-list-container {
    padding-top: 20px;
}

.box-detail-container {
    padding-top: calc(var(--header-height) + 20px);
}

.box-item-container {
    background: white;
    border-width: 0px;
    margin-bottom: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    box-shadow: inset 0px 1px 0px var(--whitef0);
    border-radius: 7px;
}

box-item-img {
    width: 12.5px;
    height: 12.5px;
}

.box-item-btn-edit {
    width: auto;
    height: auto;
    padding: 0px;
    margin-left: 7px;
}

.shipping-item-cntr {
    padding: 12px 20px;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px #F0F0F0;
    border-radius: 7px;
    margin-bottom: 10px;
}

.shipping-item-selected {
    border: 1px solid var(--primary)
}

.shipping-itrm-sts {
    width: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
}

.shipping-alias {
    padding: 3px 10px;
    flex: 0;
    height: 26px;
    margin-bottom: 5px;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 7px;
}

.box-item-name {
    padding: 3px 10px;
    border-radius: 18px;
    background: rgba(0, 0, 0, 0.85);
}

.box-loading-class {
    top: 30px !important;
}

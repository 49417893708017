
.btn-uncheck {
    position: absolute;
    bottom: calc(var(--tab-height) + 20px);
    left: 20px;
    flex: 0;
    width: calc(100% - 40px);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

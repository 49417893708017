.box-scan-container {
    padding-top: 20px;
    display: flex;
}

.box-scan-inner {
    display: flex;
    padding-top: 20vh;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 14px;
    margin-bottom: 20px;
}

.box-scan-input-placeholder {
    height: 100px;
    border-width: 0px;
    margin-bottom: 20px;
    font-size: 50px;
    font-weight: 900;
    text-align: center;
    z-index: 100;
    line-height: 100px;
    padding-left: 27px;
    padding-right: 27px;
    position: absolute;
}

.box-scan-input {
    height: 88px;
    width: calc(100% - 28px);
    z-index: 101;
    border-width: 0px;
    caret-color: var(--info);
    margin: 0px 14px;
    font-size: 50px;
    font-weight: 900;
    line-height: 88px;
    padding-left: 27px;
    padding-right: 27px;
    background: transparent;
    vertical-align: center;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08) !important;
    border-radius: 14px;
}

.result-container {
    padding: 0px 20px;
    margin-top: 20px;
}

.box-round:focus  {
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08) !important;
    border-radius: 14px;
}

.box-scan-alias {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -2px;
    text-align: center;
    margin: 10px;
}

.box-scan-divider {
    height: 20px;
}

.box-scan-result {
    height: 100px;
    text-align: center;
    border-width: 0px;
    font-size: 45px;
    font-weight: 900;
    line-height: 100px;
    padding-left: 27px;
    padding-right: 27px;
}

.box-scan-error {
    height: 100px;
    text-align: center;
    border-width: 0px;
    margin-bottom: 20px;
    font-size: 70px;
    font-weight: 900;
    line-height: 100px;
    padding-left: 27px;
    padding-right: 27px;
}

.box-scan-input:focus {
    border-width: 0px;
    background: none;
}

.box-scan-input:hover {
    border-width: 0px;
    background: none;
}


.scan-btn-ask {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.return-scan-label {
    width: 203px;
    margin-top: 20px;
    text-align: center;
}


:root {
  --highlight: #4200FF;
  --primary: #6e6eff;
  --hover: #A0A0FF;
  --dark: #141E28;
  --linkBlue: #5A5AFF;
 --black02: rgba(0,0,0, 0.02);
 --black25: rgba(0, 0, 0, 0.25);
 --black45: rgba(0, 0, 0, 0.45);
 --black65: rgba(0, 0, 0, 0.65);
  --black80: rgba(0, 0, 0, 0.8);
 --black85: rgba(0, 0, 0, 0.85);
  --greydc: #DCDCDC;
  --grey96: #969696;
  --greyd9: #D9D9D9;
  --greyc8: #c8c8c8;
  --greyc6: #C6C6C8;
  --greyc4: #141E28;
  --greyb4: #B4B4B4;
  --grey92: #929292;
  --grey93: #939393;
  --grey8c: #8c8c8c;
  --grey76: #767680;
  --grey50: #505050;
  --grey3c: #3c3c3c;
  --grey3a: #3a3a3a;
  --grey22: #222222;
  --grey26: #262626;
  --whitefa: #fafafa;
  --whitef5: #f5f5f5;
  --whitef3: #f3f3f3;
  --whitef0: #f0f0f0;
  --whitef9: #f9f9f9;
  --white25: rgba(255, 255, 255, 0.25);
  --white45: rgba(255, 255, 255, 0.45);
  --white80: rgba(255, 255, 255, 0.8);
  --white85: rgba(255, 255, 255, 0.85);
  --yellow: #FFC61A;
  --orange: #D86800;
  --pink: #FF007A;
  --error: #FF9F90;
  --lime: #00D4A1;
  --incomplete: #FF7864;
  --errorActive: #FF7864;
  --badge: #FF4D4F;
  --info: #85B6FF;
  --greyblue: #D8E3FF;
  --color-background: lightgray;
  --app-height: 100%;
  --navbar-height: 44px;
  --min-desktop-width: 768px;
  --tab-height: 83px;
  --pagination-height: 60px;
  --modal-btn-height: 44px;
  --table-width-inmap: 560px;
  --zoom-min-width: 50%;
  --top-tab-height: 48px;
  --filter-height: 320px;
  --vh: 1vh;
}

.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  max-width: 200px;
}

/*Typography*/
.h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.subtitle {
  font-size: 18px;
  font-style: normal;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.subtitle2 {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}

.body1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey26);
}

.body {
  color: var(--black85);
  font-size: 14px !important;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.body2 {
  color: var(--black85);
  font-size: 13px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.small {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.small1 {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
}

.xsmall {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
  letter-spacing: 0px;
  text-align: center;

}

.black {
  font-weight: 900;
}

.bold {
  font-weight: 700 !important;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

.text-center {
  text-align: center !important;
}

.grey92 {
  color: var(--grey92);
}

.white85 {
  color: var(--white85);
}

.lime {
  color: var(--lime);
}

.grey8c {
  color: var(--grey8c);
}

.grey93 {
  color: var(--grey93);
}

.info {
  color: var(--info);
}

.primary {
  color: var(--primary);
}

.primary-bg {
  background: var(--primary) !important;
}

.dark {
  color: var(--dark)
}


.dark-bg {
  background: var(--dark)
}

.primaryActive {
  color: var(--primary);
}

.info-bg {
  background: var(--info);
}

.white {
  color: white;
}

.white45 {
  color: var(--white45);
}

.black-cl {
  color: black;
}

.black25 {
  color: var(--black25);
}

.black45 {
  color: var(--black45);
}

.black65 {
  color: var(--black65);
}

.black45-bg {
  background: var(--black45);
}

.black85 {
  color: var(--black85);
}

.black85-bg {
  background: var(--black85);
}

.error {
  color: var(--error);
}

.errorActive {
  color: var(--errorActive) !important;
}

.border-top {
  box-shadow: inset 0px 1px 0px var(--whitef0);
}

.border-right {
  box-shadow: inset -1px 0px 0px var(--whitef0);
}

.border-left {
  border-left: 1px solid var(--whitef0);
}

.underline {
  text-decoration: underline;
}

.flex {
  flex: 1 !important;
}

.height {
  height: 100%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.nowrap {
  white-space: nowrap;
}

.preline {
  white-space: pre-line;
}

.text-inside {
  text-overflow: ellipsis;
  overflow: hidden;
}

.round-white {
  background: white;
  border-radius: 14px;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000000;
  color: white;
  padding: 8px;
  z-index: 100;
}

/*common styles*/
.container {
  min-height: var(--app-height);
  background: var(--whitef3);
}

.inner-container {
  width: calc(100% - 40px);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*
ant design button B styling
 */
.ant-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  border: 1px solid var(--linkBlue);
  width: 100%;
  height: 32px;
  border-radius: 2px;
  color: var(--linkBlue);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
}

.ant-btn:active,
.ant-btn:hover,
.ant-btn:focus {
  border-color: var(--hover);
  color: var(--hover);
}

.ant-btn[disabled] {
  color: var(--black25);
  background-color: var(--whitef5);
  border: 1px solid var(--greyd9);
}

.ant-btn-primary,
.ant-btn-primary:active {
  height: 60px;
  border-radius: 14px;
  border-width: 0;
  background: var(--primary);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: white;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: var(--hover);
}

.ant-btn-primary[disabled] {
  color: var(--black25);
  background: var(--whitef5);
}

.btn-b,
.btn-b:active {
  background: var(--grey22);
}

.btn-b:hover,
.btn-b:focus  {
  color: #fff;
  background: var(--grey3c);
}

.btn-b[disabled] {
  color: var(--black25);
  background: var(--whitef5);
}

.btn-g-border,
.btn-g-border:active {
  flex: 0!important;
  background: white !important;
  border-radius: 7px;
  color: var(--primary)!important;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
}

.btn-c:hover,
.btn-c:focus,
.btn-c:active  {
  color: #fff;
  background: var(--error);
}

.btn-c[disabled] {
  color: var(--black25);
  background: var(--whitef5);
}

.btn-g,
.btn-g:active {
  flex: 1 !important;
  background: #F0F0F0 !important;
  border: 1px solid #F0F0F0;
  /* drop-shadow / button-primary */
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043)!important;
  border-radius: 7px;
  color: var(--primary)!important;
}

.btn-g:hover {
  border: 1px solid #40A9FF;
  box-sizing: border-box;
  color: var(--hover) !important;
}

.btn-g[disabled] {
  color: var(--black25);
  background: var(--whitef5);
}

.ant-btn-text {
  border-width: 0px;
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: var(--info);
  background-color: transparent;
}

.ant-btn-text:disabled {
  color: var(--black45) !important;
  background-color: transparent;
  border: none;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-focused
{
  border-color: var(--hover);
}

.popup-input.ant-input-affix-wrapper .ant-input{
  color: white;
  font-size: 12px;
}

.popup-input.ant-input-affix-wrapper .ant-input::placeholder{
  color: var(--white45);
  font-size: 12px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--hover);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--hover);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0;
}

.ant-input-group .ant-input {
  height: 30px;
  width: 264px;
}

.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
  border-radius: 0;
}
.ant-input-search-small .ant-input-search-button {
  height: 28px;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0!important;
}
.ant-picker-range {
  width: 256px !important;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  font-size: 14px!important;
  line-height: 22px!important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 12px!important;
}
.ant-checkbox + span {
  padding-left: 8px;
  padding-right: 10px;
  font-size: 14px!important;
  line-height: 22px!important;
}
.ant-picker-input {
  width: 256px!important;
}
.ant-picker-input > input:placeholder-shown {
  font-size: 14px!important;
  line-height: 22px!important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border: 1px solid var(--primary);
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-pagination {
  background: var(--whitef3) !important;
  border-radius: 0px !important;
  width: 100% !important;
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
  border-color: var(--primary);
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
  color: var(--primary);
}

.ant-pagination-item-active {
  border-color: var(--primary);
}

.ant-pagination-item-active a {
  color: var(--primary);
}

.ant-pagination-options {
  vertical-align: top;
}

.ant-pagination.mini .ant-pagination-options-size-changer {
  bottom: 0;
  top: unset;
}


@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: bottom;
  }
}

.btn-medium {
  height: 40px;
  border-radius: 7px;
  font-size: 12px !important;
  font-weight: 700 !important;
}

.btn-medium:focus,
.btn-medium:hover
{
  height: 40px;
  border-radius: 7px;
}

/**
datepicker
**/

.ant-picker-input > input:hover {
  border-color: var(--primary) !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: var(--primary) !important;
}

.ant-picker-now-btn {
  font-size: 14px;
  height: 24px;
  padding: 0px;
}

.ant-picker-range-wrapper .ant-btn-primary{
  font-size: 14px;
  height: 24px;
  border-radius: 6px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--primary);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: var(--primary);
}
.ant-picker-today-btn {
  color: var(--primary);
}
.ant-picker-time-panel-column {
  width: 65px;
}

/*
ant drawer
 */

.ant-drawer-body {
  padding: 10px 0px;
}

.ant-drawer-mask {
  background-color: transparent;
}
.ant-drawer-header {
  padding: 0px;
  height: 0px;
}

.ant-message {
  position: fixed;
  bottom: 120px !important;
  top: unset !important;
  color: white;
}

.ant-message-notice-content {
  background-color: var(--grey26);
  border-radius: 7px;
}

/*
  input styling
 */

.ant-input {
  height: 60px;
  width: 100%;
  border-radius: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--grey26);
}


.ant-input:hover,
.ant-input:focus,
.ant-input-focused
{
  border-color: var(--hover);
}

.ant-input-affix-wrapper {
  align-items: center;
  height: 60px;
  width: 100%;
  border-radius: 14px;
}

.ant-input-affix-wrapper .ant-input {
  height: 60px;
  background-color: transparent;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--hover);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus {
  border-color: var(--hover);
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: var(--primary);
}

.ant-input:disabled {
  background-color: var(--greydc);
}

.ant-input::placeholder {
  color: var(--grey8c);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

/*select*/
.ant-select-item {
  min-height: 30px;
  color: var(--black85);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  align-items: center;
  text-align: left;
}

.ant-select-selection-placeholder {
  color: var(--black45);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.ant-select-arrow {
  color: var(--black85);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector
{
  border-radius: 0px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  color: white;
  font-weight: bold;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  color: var(--grey8c);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  height: 40px;
  min-width: 80px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 14px;
  background: var(--primary);
  border: none;
  height: 42px;
  align-items: center;
}

.ant-dropdown-menu {
  border-radius: 7px;
}

/*modal custom design*/
.ant-modal-mask {
  background: var(--black25);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal-content {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  white-space: pre-line;
  backdrop-filter: blur(48px);
  background: var(--black85);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 21.3869px;
}

.ant-modal-confirm .ant-modal-body {
  padding: 19px 0px 0px 0px;
  background: var(--black85);
  border-radius: 21px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-weight: 700;
  color: white;
  text-align: center;
}

.lg-modal {
  border-radius: 7px;
  max-width: 986px!important;
  overflow: hidden;
  overflow-x: scroll;
}

.dark-table {
  background: var(--dark);
}

.sm-modal {
  max-width: 305px;
  width: 305px;
  overflow: hidden;
}

.md-modal {
  max-width: 335px;
  width: 335px;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 19px;
  font-size: 12px;
  color: var(--grey92);
  text-align: center;
  width: 295px;
  border-bottom: 1px solid var(--black85);
}

.ant-modal-confirm-body > .anticon {
  transform: scale(0);
  width: 0px;
  padding: 0px;
  margin: 0px
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0px;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 30px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-confirm {
  max-width: 295px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  color: var(--primary);
  font-size: 14px;
  flex: 1;
  width: 100%;
  margin: 0px;
}

.ant-modal-confirm-btns .ant-btn{
  color: white;
  border: none;
  background: none;
  padding: 0px;
  margin: 0px
}

.ant-modal-confirm .ant-modal-confirm-btns {
  height: var(--modal-btn-height);
  padding: 0px;
  width: 100%;
  margin: 0px;
  display: flex;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn  {
  height: var(--modal-btn-height) !important;
  padding: 0px;
  border-radius: 0px;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  flex: 1;
}

.ant-modal .ant-modal-btns .ant-btn  {
  color: whitesmoke;
  width: 100%;
  height: var(--modal-btn-height) !important;
  font-weight: 700;
  font-size: 14px;
  flex: 1;
}

.modal-divider {
  height: var(--modal-btn-height);
  width: 1px;
  background: var(--black85);
}

.popup-cancel {
  height: var(--modal-btn-height);
  padding: 0px;
  text-align: center;
  color: white;
  width: 50%;
}

.popup-ok {
  height: var(--modal-btn-height);
  padding: 0px;
  width: 50%;
  text-align: center;
  color: var(--primary)
}

.popup-ok:hover {
  color: var(--primary)
}

.ant-modal-footer {
  padding: 0px;
  padding-bottom: 0px;
  text-align: right;
  background: transparent;
  border-radius: 0 0 2px 2px;
  margin: 0px;
  border: none;
}
.ant-modal-body {
  padding: 20px 20px 10px;
  border-bottom: 1px solid var(--black85);
}

/*checkbox*/
.ant-checkbox-group-item {
  margin-bottom: 4px;
  margin-top: 4px;
}


.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  height: 32px;
}
.ant-table .ant-checkbox-wrapper {
  top: -3px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px;
}

  /*collapse*/
.ant-collapse {
  background-color: var(--whitefa);
  padding: 0px 0px;
  border-width: 0px;
  border-radius: 0px;
  border-bottom: 0px;
  box-shadow: none;
}

.ant-collapse-item {
  background-color: var(--whitefa);
  padding: 0px 0px;
  margin: 0px;
  box-shadow: none;
  border-bottom: 0px;
}

.ant-collapse-content {
  border-top: none;
  background-color: var(--whitefa);
}

/* progress*/
.ant-progress {
  border-radius: 10px;
  border: 1px solid #000000;
}

.ant-progress-inner  {
  margin-bottom: 2px;
  padding-left: 1px;
  padding-right: 1px;
}

.bottom-btn {
  width: calc(100% - 40px);
  position: fixed;
  right: 20px;
  left: 20px;
  bottom: 34px;
}

.icon:hover {
  fill: #DA4567;
}

.minus {
  fill: var(--greyd9)
}

.minus:hover {
  fill: var(--greyb4)
}

.camera-inner-circle:hover {
  transform: scale(0.95,0.95);
  align-self: center;
  transition: scale 1s ease;
  transform-origin: center center;
}

/*ant-tooltip*/
.ant-tooltip .ant-tooltip-inner {
  background: #222222;
  border-radius: 14px;
}

.row {
  display: flex;
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.popup-input {
  margin-bottom: 16px;
  margin-top: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  height: 32px;
  background: rgba(0, 0, 0, 0.85);
  border: 0.5px solid rgba(0, 0, 0, 0.45);
  border-radius: 7px;
}

.popup-input::placeholder {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--white45);
}


.map-floating-cntr {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: end;
  top: 100px;
}

.zoom-pushtoright {
  visibility: visible !important;
  overflow: hidden;
  left: 0;
  right: 0;
  width: var(--table-width-inmap);
}

.zoom-default {
  left: 0;
  right: 0;
  visibility: hidden;
}

.map-right-floating {
  right: 16px
}

.map-left-floating {
  left: 16px
}

.plan-map-right-control {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.plan-map-slider-control {
  position: absolute;
  top: 89px;
  right: 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.ant-slider-vertical {
  width: 8px;
  height: 109px;
  margin: 0px;
  z-index: 100;
  padding: 0px 2px;
}

.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -4px;
}

.ant-slider-rail {
  z-index: 11;
  height: 100%;
  background-color: var(--greyd9);
  border-radius: 0px;
  transition: background-color 0.3s;
}

.ant-slider:hover .ant-slider-rail {
  background-color: var(--greyd9);
}
.ant-slider:hover .ant-slider-track {
  background-color: var(--highlight);
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: var(--greyd9);
}

.ant-slider-dot {
  top: -2px;
  left: -3px;
  width: 14px;
  height: 1px;
  z-index: 10;
  background-color: var(--greyd9);
  border-width: 0px;
  border-radius: 0;
  cursor: pointer;
}
.ant-slider-dot-active {
  border-width: 0px;
}

.ant-slider-track {
  z-index: 12;
  background-color: var(--highlight);
  border-radius: 0px;
  transition: background-color 0.3s;
}
.ant-slider-handle:focus {
  border-color: var(--highlight);
  outline: none;
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: var(--highlight);
}

.ant-slider-handle {
  position: absolute;
  z-index: 15;
  width: 12px;
  height: 5px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 1px var(--highlight);
  border-radius: 0px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.ol-touch .ol-zoom .ol-zoom-out {
  margin-top: 212px;
}

.ol-zoom-in {
  right: 16px !important;
  top: 56px !important;
  width: 32px !important;
  height: 32px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  background: white;
}

.ol-zoom-out {
  right: 16px !important;
  top: 217px !important;
  width: 32px !important;
  height: 32px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  background: white;
}

.floating-btn
{
  height: 48px;
  width: 48px;
  border-radius: 7px;
  padding: 3px 10px 3px 10px;
  font-weight: bold;
  font-size: 13px;
  color: var(--primary);
  margin-bottom: 5px;
  background: white;
  border-width: 0px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 6px 30px rgba(0, 0, 0, 0.04), 0px 8px 10px rgba(0, 0, 0, 0.08);
}


.dot {
  width: 6px;
  height: 6px;
  background: var(--primary);
  border-radius: 33px;
  margin-right: 10px;
}

.red {
  background: #FF4D4F;
}

.green {
  background: #52C41A;
}

.grey {
  background: var(--greyd9);
}

@media print {
  .printPageBreak {
    page-break-after: always !important;
    page-break-inside: avoid !important;
  }

  html, body { height: initial !important; overflow: initial !important;   position: initial;}
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

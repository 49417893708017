.inputDivider {
    background: var(--primary);
    border-radius: 2px;
    width: 3px;
    height: 23px;
}

.divider {
    margin: 13px
}

.planInput {
    width: 46px !important;
    height: 20px;
    background: none;
    border: none !important;
    border-radius: 0px;
    font-size: 12px;
    font-weight: bold;
    color: white;
}

.planInput::placeholder {
    font-size: 12px;
    font-weight: bold;
    color: var(--white45);
}

.select {
    margin-right: 13px;
    justify-content: space-between;
    width: 90px;
}

.content {
    height: 35px;
    margin-top: 16px;
    margin-bottom: 20px;
}

.itemContainer {
    height: 30px;
    padding: 5px;
    margin-bottom: 16px;
    justify-content: space-between;
}

.itemType {
    border-radius: 4px;
    background: var(--black85);
    text-align: center;
    line-height: 20px;
    height: 20px;
    min-width: 80px;
}

.lottieVisible {
    position: absolute;
    z-index: 100;
    bottom: 100px;
    left: 0;
    right: 0;
}

.mergeTitle {
    margin-bottom: 5px;
    text-align: center;
}

.btnIcon {
    width: 14px;
    height: 14px;
}
.planBtn,
.planBtn:active,
.planBtn:focus
{
    box-shadow: 0 4px 4px rgba(0,0,0,0.2);
    background: #4200ff;
    height: 32px;
    border-radius: 6px !important;
}
.planBtn:hover
{
    height: 32px;
    border-radius: 6px !important;
}

.planBtnInner {
    gap: 5px;
    font-size: 13px;
}

.planBtnWhite,
.planBtnWhite:hover,
.planBtnWhite:active,
.planBtnWhite:focus
{
    background-color: #fff !important;
    color: #000 !important;
}

.btnQuad {
    width: 32px !important;
}

.table {
    border-radius: 6px;
    border: 1px solid var(--greyd9);
    background: #FFF;
    overflow: hidden;
}

.planTable {
    min-height: 352px;
    width: 100%;
    height: 100%;
}

.planCell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.planCluster {
    border-radius: 4px;
    background: #f0f0f0;
    height: 20px;
    color: var(--black85);
    padding: 0px 4px 0px 8px;
}

.planDisplay {
    border-radius: 4px;
    background: #E6F7FF;
    height: 20px;
    color: #096DD9;
    padding: 0px 8px;
}

.bunnyType {
    border-radius: 4px;
    background: #E6F7FF;
    height: 20px;
    color: #096DD9;
    padding: 0px 8px;
    width: auto;
}

.BLACK {
    background: #262626;
    color: #fff;
}

.WHITE {
    background: #f0f0f0;
    color: #000;
}

.YELLOW {
    background: #FFFBE6;
    color: #D48806;
}

.ORANGE {
    background: #FFF2E8;
    color: #FA541C;
}

.OFFROAD {
    background: #FFF1F0;
    color: #CF1322;
}

.RAINBOW {
    background: #FFF1F0;
    color: #CF1322;
}

.radioGrp {
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    width: 100%
}

.stsCount {
    margin-right: 5px;
}

.stsLoading {
    margin-top: 12px;
}

.stsCntr {
    background: var(--dark);
    border-radius: 14px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    text-align: left;
    flex: 1;
    color: white;
    min-width: 50px;
    box-shadow: 0px 4px 8px 0px #0000001A;
}

@media (max-width: 768px){
    .stsCntr {
        flex-direction: column;
        height: 124px;
        padding: 16px;
    }
}

.cntr {
    padding-top: 10px;
    min-width: 947px;
}

.controlCntr {
    padding: 10px 20px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    border-radius: 7px;
    margin-bottom: 10px;
}

.dot {
    width: 6px;
    height: 6px;
    background: var(--primary);
    border-radius: 33px;
    margin-right: 10px;
}

.inactive {
    background: var(--greyd9);
}

.btnPrint {
    margin-left: 10px;
    /*padding: 0px 12px;*/
    width: 150px;
    height: 40px;
    background: var(--primary);
    border-radius: 7px;
    color: var(--white80);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.btnPrint:hover,
.btnPrint:focus  {
    font-weight: 700;
    height: 40px;
    border-radius: 7px;
    font-size: 12px;
}

.itemCntr {
    padding: 20px;
    background: #FFFFFF;
    /* Neutral / 4 */
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.itemCntr:active,
.itemCntr:hover {
    /* Neutral / 4 */
    border: 1px solid var(--primary);
}

.itemLabel {
    margin-top: 8px;
}

.itemImg {
    width: 40px;
    height: 40px;
}

.bunny-type {
    border-radius: 4px;
    height: 20px !important;
    line-height: 20px;
    padding: 0px !important;
    margin: 0px !important;
    width: 60px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bunny-blue {
    background: var(--primary);
    color: var(--whitef3);
}

.bunny-white {
    background: var(--whitef0);
    color: var(--primary)
}

.bunny-offroad {
    background: var(--orange);
    color: var(--whitef3);
}

.bunny-rainbow {
    background: var(--pink);
    color: var(--whitef3);
}

.bunny-yellow {
    background: var(--yellow);
    color: white;
}

.bunny-orange {
    background: #FFF2E8;
    color: #FA541C;
}

.plan {
    background: var(--whitef3);
}

.bunny-black {
    background: var(--dark);
    color: var(--whitef3);
}

.type-option-arrow {
    margin-left: 5px;
}

.staff {
    background: var(--whitef3);
    color: black;
}

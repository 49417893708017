.leftContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
}

.leftInnerContainer {
    display: flex;
    flex-direction: column;
    padding-top: 106px;
}

.titleInner {
    color: rgba(0, 0, 0, 0.85);
}

.subtitleInner {
    text-align: left;
    white-space: pre-line;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 10px;
}

.info-container {
    padding: 10px;
    color: white;
    background: #151619;
    box-shadow: 0px 2px 4px rgba(17, 24, 39, 0.08);
    border-radius: 4px;
}

.dot-container {
    width: 40px;
    justify-content: flex-end;
}

.info-title {
    line-height: 28px;
}

.info-row {
    margin-bottom: 4px;
    justify-content: space-between;
}

.info-label {
    width: auto;
    margin-right: 10px;
}

.info-label-shipping {
    margin-left: 10px;
}

.info-btn {
    z-index: 101;
    background: none;
    padding: 0px;
}

.container {
    display: flex;
    border-radius: 2px;
    border: 1px solid #D9D9D9;
    background: white;
    overflow-x: hidden !important;
    height: 100%;
    overflow-y: scroll;
    padding: 12px;
}

.picker {
    border-radius: 2px !important;
    height: 32px;
    width: 216px;
    font-size: 14px !important;
    color: var(--black85)
}

.sectorSearch {
    width: 100%;
    border: 1px solid var(--greyd9);
    margin-bottom: 3px;
}

.picker::placeholder {
     font-size: 16px;
     text-align: left;
     color: var(--black25)
 }

.check {
    align-items: center;
    padding: 5px 12px;
    height:32px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.scroll {
    flex: 1;
    justify-content: flex-start;
    overflow-y: scroll;
    margin: 12px;
}

.sectorCheckGroup {
    padding: 0px 8px;
    display: flex;
    height: 250px;
    flex-direction: column;
    overflow-y: scroll;
}

.sectorCheckWrapper{
    height: 100%;
    padding-top: 2px;
}

.sectorCheckAll {
    padding: 8px;
}

.shippingList {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
}

.list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    /*background: red;*/
    /*flex-grow: 1;*/
}

.bottom {
    /*border: 1px solid rgba(0, 0, 0, 0.06);*/
    background: white;
    padding-right: 10px;
}

.reset {
    background: white;
    width: unset;
    height: 38px;
    border-radius: 2px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    color: var(--lime) !important;
}
.reset:hover,
.reset:focus,
.reset ant-input-focused {
    background: white;
    width: unset;
    height: 38px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    color: var(--lime) !important;
}

.dot {
    width: 6px;
    height: 6px;
    background: var(--green);
    border-radius: 33px;
    margin-right: 4px;
}

.green {
    background: #52C41A;
}

.timeContainer {
    /*margin-bottom: 10px;*/
}

.timer {
    min-width: 342px;
    /*max-width: 342px;*/
    border-radius: 2px;
    marginRight: 10px;
    fontSize: 14px;
    lineHeight: 22px;
}

.timeDivider {
    width: 8px;
}

.grey {
    background: var(--grey3a);
}

.tooltipCntr {
    padding: 10px;
    color: white;
    background: #151619;
    box-shadow: 0px 2px 4px rgba(17, 24, 39, 0.08);
    border-radius: 4px;
}

.tooltipCntr:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.tooltipCntr:after {
    border-top-color: #151619;
    border-width: 7px;
    left: 48px;
    margin-left: -7px;
}

.sectorName {
    padding: 0
}

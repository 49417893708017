
.plan-filter-cntr {
    min-width: 498px !important;
    max-width: 498px !important;
    margin-right: 10px;
    height: calc(100vh - 126px);
    overflow-y: scroll;
}

.round-border-cntr {
    border-radius: 6px;
    border: 1px solid var(--greyd9);
    background: #FFF;
    overflow: hidden;
}

.plan-top-float {
    top: 10px !important;
    left: 10px !important;
    display: flex;
    flex-direction: row;
    gap: 9px;
}

.plan-header {
    background: white;
    border-radius: 6px;
    padding: 4px 8px;
    min-height: 40px !important;
    height: 40px !important;
    overflow: hidden;
    margin-bottom: 10px;
    justify-content: space-between;
}

.plan-filter-upper {
    height: 70%;
    max-height: 70%;
    width: 100%;
    overflow-y: hidden;
}

.plan-table {
    flex: 1;
    width: 100%;
    height: 100%;
}

.sector-table {
    min-width: 114px;
    max-width: 114px;
    height: 100%;
    overflow-y: scroll;
}

.plan-filter-lower  {
    width: 100%;
    height: 302px;
    border-radius: 2px;
    border: 1px solid var(--greyd9);
    background: #FFF;
    overflow: hidden;
}

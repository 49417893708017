.headerContainer {
    display: flex;
    align-items: center;
    height: 44px;
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    background: var(--black02);
    backdrop-filter: blur(30px);
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    border-bottom: 0.5px solid var(--greyc6);
}

.leftNode {
    flex: 20vw;
    color: var(--hover);
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: left;
}

.leftNode:hover {
    color: var(--linkBlue);
}

.rightNode {
    flex: 20vw;
    color: var(--hover);
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: right;
}

.rightNode:hover {
    color: var(--linkBlue);
}

.titleNode {
    width: 100%;
    color: black;
}

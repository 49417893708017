.container {
    background: none;
    padding: 0px;
    margin: 0px;
    height: 68px
}

.radioGrp {
    margin: 16px 0px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.list {
    min-height: 300px;
}

.subtitle {
    margin: 5px;
}
.white-bold {
    font-weight: bold;
    color: white;
}
.white-small {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.45);
    margin-top: 6px;
}
.name {
    margin-left: 8px;
    margin-right: auto;
    max-width: 140px;
    padding: 5px 0px;
}

.phone {
    /*margin-top: 5px;*/
    /*margin-bottom: 5px;*/
}

.type {
    border-radius: 4px;
    text-align: center;
    line-height: 20px;
    height: 20px;
    min-width: 80px;
}

.dot {
    width: 6px;
    height: 6px;
    background: var(--primary);
    border-radius: 33px;
    margin-right: 10px;
}

.red {
    background: #FF4D4F;
}

.green {
    background: #52C41A;
}
.fee-input {
    outline: none;
    border: none;
    color: rgba(255, 255, 255, 0.85);
    margin-top: 16px!important;
    background-color: black!important;
    width: 100%!important;
    height: 32px!important;
    padding-left: 10px!important;
    font-weight: 500!important;
    font-size: 12px!important;
    line-height: 20px!important;
    border-radius: 7px!important;
}
.modal-root {
    width: 467px!important;
    height: auto!important;
    padding-top: 20px!important;
    padding-left: 16px!important;
    padding-right: 16px!important;
}
.modal-header {
    display: flex!important;
    width: 100%!important;
    justify-content: center!important;
    font-weight: 700!important;
    color: white!important;
}

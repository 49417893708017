.picker {
    margin-right: 10px;
    border-radius: 2px !important;
    height: 32px;
    width: 120px;
    font-size: 13px !important;
}

.dropButton {
    background: var(--primary) !important;
    color: white !important;
    padding: 5px 16px;
    border-radius: 2px;
}

.optionLabel {
    padding-right: 10px;
    padding-top: 10px;
    font-size: 13px !important;
}


.searchInputCntr {
    margin-left: 5px;
    min-width: 240px !important;
    max-width: 260px;
    height: 32px !important;
    border-radius: 2px;
    align-items: center;
}

.searchInput {
    height: 100%;
    border-radius: 2px;
    font-size: 13px;
    border: 1px solid #D9D9D9;
}

.searchInput::placeholder {
    font-size: 13px !important;
    color: var(--black25)
}

.searchBtn {
    height: 32px;
    width: 32px;
    border-radius: 2px;
    border: 1px solid #D9D9D9;
}

.searchIcon {
    height: 14px;
    width: 14px;
}

.checkCntr {
    display: flex;
    flex-direction: row;
}

.searchCheck {
    margin-left: 10px;
    height: 40px;
    align-items: center;
}

.searchLabel {
    width: 100px;
    visibility: visible;
}

.container {
    padding: 10px;
    background: #FFFFFF;
    /*border: 1px solid rgba(0, 0, 0, 0.06);*/
    box-sizing: border-box;
    border-radius: 7px;
    margin-bottom: 10px;
    overflow: hidden;
}

.searchCntr {
    justify-content: end;
}

.controlBtn {
    display: flex;
    flex-direction: row;
    background: var(--white25);
    border: 1px solid rgba(0, 0, 0, 0.06);
    overflow: hidden;
    margin-left: 10px;
}

.btnPrint {
    height: 32px;
    border-radius: 2px;
    font-size: 14px;
}

.btnPrint:hover,
.btnPrint:focus  {
    font-weight: 700;
    height: 32px;
    border-radius: 2px;
    font-size: 14px;
}

/*@media (max-width: 868px){*/
/*    .controlBtn {*/
/*        visibility: hidden;*/
/*        width: 0px;*/
/*        transform: scale(0);*/
/*    }*/
/*}*/
/*@media (max-width: 1200px){*/
/*    .searchCntr {*/
/*        flex-direction: column;*/
/*    }*/

/*    .checkCntr {*/
/*        padding-top: 10px;*/
/*        margin-left: 0px;*/
/*    }*/
/*}*/

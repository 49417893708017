.ant-breadcrumb > span:last-child {
    font-weight: 700;
}

.ant-breadcrumb-separator {
    margin-right: 8px;
    margin-left: 8px;
}

.ant-breadcrumb {
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.link:hover {
    color: var(--black65)
}

.breadcrumb-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 100;
    justify-content: space-between;
}

.breadcrumb-btn-left {
    padding: 0px;
    margin: 0px;
    width: auto !important;
    height: auto !important;
}

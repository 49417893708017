
.wrapLoading {
    padding: 10px;
    width: 380px;
    margin: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.btnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}

.divider {
    width: 20px;
}

.divideLine {
    width: 100%;
    height: 1px;
    background: #D7D7DB;
    margin: 10px 0px;
}

.btn {
    width: 136px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 7px;
}

.printContainer {
    height: 500px;
    overflow-y: scroll;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    padding-bottom: 0px;
    margin: 0px;
    border-radius: 7px;
    direction: flex;
}

.btnCancel {
    color: white;
    width: 136px !important;
    height: 40px !important;
    font-size: 14px;
    font-weight: 700;
}

.btnGrey {
    color: var(--primary);
    background: var(--whitef3);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 175px !important;
    height: 40px !important;
}

.btnCntr {
    margin: 10px 4px 0px 4px;
    width: 240px !important;
}

.cntr {
    padding-top: 0 !important;
}

.darkCntr {
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 8px 10px 8px 16px;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    background: #141E28;
    height: 124px;
}
.darkCntr:hover,
.darkCntr:active
{
    background: #141E28;
    height: 124px;
}

.profileCntr {
    margin-top: 16px;
    height: 20px;
    margin-bottom: 11px;
}

.toggleCntr {
    max-width: 343px;
    background: var(--whitef3);
    border-radius: 8.91px;
}

.toggleText {
    font-size: 12px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.5px;
    text-align: center;
    border: none;
    color: black;
    height: 27px;
    padding: 0px !important;
}

.currentDate {
    padding-bottom: 10px
}

.cnt {
    margin-top: 10px;
}

.cntBlack {
    margin-left: 17px;
    background: black;
    height: 30px;
    padding: 5px 10px;
    border-radius: 100px;
}

.image {
    height: 40px;
    width: 40px;
    margin-top: 22px;
}

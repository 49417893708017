.cntr {
    padding-top: 10px;
    /*min-width: 947px;*/
}

.statusCntr {
    margin-bottom: 10px;
}

.statusDivider {
    width: 5px;
}

.cellStatus {
    background: var(--errorActive);
    border-radius: 4px;
    color: var(--whitef3);
    display: flex;
    width: 55px;
    height: 20px;
    align-items: center;
    justify-content: center;
}

.cellBtn,
.cellBtn:hover,
.cellBtn:active,
.cellBtn:focus
{
    height: 32px;
    padding: 0px 17px;
    background: var(--whitef5);
    box-sizing: border-box;
    border-radius: 7px;
    color: var(--primary);
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
}

.cellBtnInactive {
    border: 1px solid var(--greyd9);
    color: var(--black25);
}

.dot {
    width: 6px;
    height: 6px;
    background: var(--primary);
    border-radius: 33px;
    margin-right: 10px;
}

.inactive {
    background: var(--greyd9);
}

.pickup {
    background: var(--errorActive);
}

.shipping {
    background: var(--whitef0);
    color: var(--primary);
}

.mixed {
    background: var(--primary);
}

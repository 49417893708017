.cntr {
    padding-top: 10px;
    /*min-width: 947px;*/
}

.statusCntr {
    margin-bottom: 10px;
}

.statusDivider {
    width: 5px;
}

.cellStatus {
    background: var(--errorActive);
    border-radius: 4px;
    color: var(--whitef3);
    display: flex;
    width: 55px;
    height: 20px;
    align-items: center;
    justify-content: center;
}

.cellBtn,
.cellBtn:hover,
.cellBtn:active,
.cellBtn:focus
{
    height: 32px;
    width: 87px;
    background: var(--whitef5);
    box-sizing: border-box;
    border-radius: 7px;
    color: var(--primary);
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
}

.cellBtnInactive {
    border: none;
    color: var(--black25);
}

.cellBtn:disabled {
    border: none;
    color: var(--black25);
}

.btnUnderline {
    margin-left: 5px;
}

.pickup {
    background: var(--errorActive);
}

.shipping {
    background: var(--whitef0);
    color: var(--primary);
}

.mixed {
    background: var(--primary);
}

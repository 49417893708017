.container {
    width: 32px;
    height: 127px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


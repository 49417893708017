.refresh-cntr {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: center;
}

.refresher {
    pointer-events: none;
    --refresh-width: 55px;
    background: #fff;
    width: var(--refresh-width);
    height: var(--refresh-width);
    border-radius: 50%;
    position: absolute;
    left: calc(50% - var(--refresh-width) / 2);
    padding: 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
    transition: all 300ms cubic-bezier(0,0,0.2,1);
    will-change: transform, opacity;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    visibility: hidden;
}

.refresher.refreshing {
    transform: scale(1);
    z-index: 1;
    visibility: visible;
}

.refresher.refreshing.rotate {
    -webkit-animation: rotation 0.5s  linear;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

.refresher.refreshing.rotate.shrink {
    transform: scale(0);
    opacity: 0;
}

.refresher.done {
    transition: none;
}

.refreshList {
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.refreshList.done {
    transform: none;
    transition: none;
    touch-action: auto;
    filter: none;
}

.refreshList.refreshing {
    filter: blur(1px);
    touch-action: none; /* prevent scrolling */
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }
}

.table-column {
    min-width: 80px;
}

.table-cell {
    height: 56px;
}

.table-link {
    color: var(--linkBlue) !important;
    text-decoration: underline !important;
}

.sort-cntr {
    margin-left: 9px;
}

.paginationStyle {
    background: white;
    border-radius: 7px;
    margin-left: auto;
    height: var(--pagination-height);
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.sort-btn {
    display: inline-block;
    vertical-align: top;
    line-height: normal;
    height: 5.12px;
    margin: 1.4px
}

.table-container {
    /*position: relative;*/
    display: block;
    /*border-radius: 7px;*/
    overflow: hidden;
    background: white;
    height: 100%;
    /*height: calc(100% - var(--pagination-height));*/
}

.table {
    overflow: hidden;
    height: 100%
}
.table .pagination {
    height: calc(100vh - var(--pagination-height) - 1000px);
}

.box-register-item {
    height: 168px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 10px;
}

.tooltip {
    position: absolute;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    bottom: 12px;
    left: -50px;
    z-index: 100;
    min-width: 280px;
}


.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}

.filterTitle {
    margin-bottom: 10px;
    padding-right: 0 !important;
}

.box-register-item-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.box-register-item-divider {
    box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.06);
    width: 1px;
    height: 100%;
    margin-left: 16px;
    margin-right: 16px;
}

.box-empty {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 14px;
    height: 102px;
    display: flex;
    justify-content: center;
    padding-left: 24px;
    flex-direction: column;
}

.box-register-loading {
    top: -20px !important;
    width: 20px;
    height: 20px;
}

.loading-refresh {
    display: block;
    -webkit-animation: rotation 0.4s linear;
    width: 30px;
    height: 20px;
}

.ptr-element {
    width: 30px;
    height: 20px;
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    align-self: center;
}

.pull-refresh-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.refresh-view {
    width: 100%;
}

.shipping-map-container {
    margin: 0px;
    padding: 0px;
    width: 100%;
    overflow: hidden !important;
    height: calc(100vh - 48px) !important;
    position: relative;
}

.shipping-header {
    background: white;
}

.shipping-header .control {
    margin-right: 10px;
}

.container.shipping-map {
    width: 100%;
}

.shipping-map.column {
    padding: 16px;
    height: 100%;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.box-sort-container {
    max-width: 100px;
    height: 40px !important;
    flex: 1;
    margin-left: 10px;
}

.box-result-container {
    display: flex;
    margin-bottom: 10px;
    background: white;
    border-radius: 7px;
    padding: 10px 0px;
}

.box-sort-label {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    min-width: 50px;
    max-width: 90px;
}

.box-item-alias-cntr {
    height: 32px;
    width: 180px;
    background: var(--whitef3);
    align-items: center;
    margin-bottom: 10px;
    padding: 2px;
    border-radius: 8.91px;
    position: relative;
}

.box-item-sector.active {
    background: #6E6EFF;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    border-radius: 6.93px;
    right: 2px;
    transition: right 1000ms cubic-bezier(0, 0, 0.2, 1);
}

.box-item-sector:active,
.box-item-sector:focus {
    height: 28px;
}

.box-item-sector.inactive {
    background: var(--grey96) !important;
    border: 0.5px solid rgba(0, 0, 0, 0.04) !important;
    border-radius: 6.93px !important;
}

.box-item-sector.inactive:active,
.box-item-sector.inactive:focus {
    left: 5px;
    transition: left 1000ms ease-in;
}

.search-btn {
    height: 32px !important;
    border-radius: 2px;
}

.box-item-sector {
    height: 28px;
    width: 88px;
    color: white !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.filter-cntr {
    height: 318px !important;
    padding: 8px;
    overflow-x: scroll;
    background: rgba(255, 255, 255, 0.85);
    gap: 10px;
    display: flex;
    flex-direction: row;
}

.shiping-header {
    background: #fff;
    height: 40px;
}

.shiping-search {
    height: 32px;
    width: 264px;
    border-radius: 2px;
    margin-right: 5px;
}

.shipping-float {
    top: 10px !important;
    right: 10px !important;
}

@media (max-width: 768px) {
    .box-sort-label {
        max-width: 0px;
        min-width: 0px;
        height: 0px;
        transform: scale(0);
        margin-left: 10px;
    }
}

.whiteCntr {
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    /*box-sizing: border-box;*/
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    height: 124px;
}

.label {
    margin-bottom: 22px;
}

.countsContainer {
    width: 100%;
}

.countContainer {
    width: 100%;
}

.count {
    margin-top: 8px;
}
